import React, { Component } from "react";
import { data } from "./HanDb";
import BoardText from "./function/BoardText";

import { Pagination } from "../../Common/Pagination/Pagination";
import "./style/Hboard.scss";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { Table } from "antd";
import { formatDate } from "../../utils/func";
import NoticeService from "../../Modules/API/Notice.service";
import { withRouter } from "react-router-dom";
import FilterBox from "../../Components/Filter/FilterBox";
import { TRADINGFORM_OPTIONS, REGION_OPTIONS } from "../../constant/FilterData";

const columns = [
  {
    title: "번호",
    dataIndex: "index",
    key: 1,
    width: "10%",
  },
  {
    title: "지역",
    dataIndex: "region",
    key: 2,
    width: "12%",
  },
  {
    title: "매매 형태",
    dataIndex: "tradingForm",
    key: 2,
    width: "15%",
  },
  {
    title: "종류",
    dataIndex: "type",
    key: 3,
    width: "15%",
  },
  {
    title: "제목",
    dataIndex: "title",
    key: 4,
    width: "25%",
  },
  {
    title: "작성일",
    key: 5,
    dataIndex: "date",
    width: "10%",
  },
  {
    title: "진행",
    key: 6,
    dataIndex: "progress",
    width: "18%",
  },
];
class Hboard extends Component {
  state = {
    currentPage: 0,
    cardsPerPage: 7,
    total: 0,
    currentPageM: 0,
    cardsPerPageM: 6,
    list: [],
    dataTable: [],
    // cardsperpage -> 보여주고 싶은 박스 개수
    selectedRegion: "",
    selectedTradingForm: "",
  };

  handleRegionSelect = (region) => {
    this.setState({ selectedRegion: region }, this.getDataInfo);
  };

  handleTradingFormSelect = (tradingForm) => {
    this.setState({ selectedTradingForm: tradingForm }, this.getDataInfo);
  };

  onPageNumberClicked = (newPage) => (event) => {
    this.handlePageNumberClicked(newPage);
  };
  onPreviousPageClicked = (newPage) => (event) => {
    this.handlePreviousPageClicked(newPage);
  };
  onNextPageClicked = (newPage) => (event) => {
    this.handleNextPageClicked(newPage);
  };
  handlePageNumberClicked = (newPage) => {
    // console.log("onPageNumberClicked", newPage);
    this.setState({ currentPage: newPage });
  };
  handlePreviousPageClicked = (newPage) => {
    // console.log("onPreviousPageClicked", newPage);
    this.setState({ currentPage: newPage });
  };
  handleNextPageClicked = (newPage) => {
    //console.log("onNextPageClicked", newPage);
    this.setState({ currentPage: newPage });
  };
  filterData = () => {
    let getData = data;
    let startIndex = this.state.currentPage * this.state.cardsPerPage;
    let endIndex = (this.state.currentPage + 1) * this.state.cardsPerPage;

    return getData.slice(startIndex, endIndex);
  };

  componentDidMount = () => {
    this.getAllList();
    this.getDataInfo();
  };
  getAllList = async () => {
    const rID = "hanbit_notice";
    await axios
      .get(`http://61.73.79.136:9229/api/resources?rID=${rID}`)

      .then((response) => {
        console.log(response);
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.rows
        ) {
          response.data.data.rows = response.data.data.rows.map((item) => {
            const date = new Date(item.simple_resources.createdAt);
            item.simple_resources.createdAt = `${date.getFullYear()}-${
              date.getMonth() + 1
            }-${date.getDate()}`;
            return item;
          });

          this.setState({
            ...this.state,
            list: response.data.data.rows,
          });
        } else {
          console.error("error");
        }
      })
      .catch((e) => console.log(e));
  };

  getDataInfo = async () => {
    const content_type = "hanbitBoard";
    const { selectedRegion, selectedTradingForm } = this.state;

    try {
      const res = await NoticeService.getVehiclesNotices(content_type);

      if (res.data && res.data.items) {
        const mappedData = res.data.items
          .map((item, index) => ({
            index: index + 1,
            type: item.fields.type,
            title: item.fields.title,
            date: formatDate(item.fields.dateCreated),
            region: item.fields.region || "",
            tradingForm: item.fields.tradingForm || "",
            progress: item.fields.progress || "",
            detail: item.fields?.detail || "",
            key: index,
          }))
          .filter((item) => {
            const matchesRegion =
              !selectedRegion || item.region === selectedRegion;
            const matchesTradingForm =
              !selectedTradingForm || item.tradingForm === selectedTradingForm;
            return matchesRegion && matchesTradingForm;
          });

        this.setState({
          dataTable: mappedData,
          total: mappedData.length,
        });
      }
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  };

  renderList = () => {
    const list = this.state.list;
    let startIndex = this.state.currentPage * this.state.cardsPerPage;
    let endIndex = (this.state.cardsPerPage + 1) * this.state.cardsPerPage;

    return list.slice(startIndex, endIndex);
  };

  handleRowClick = (record, dataTable) => {
    this.props.history.push(`/hanbitboard-detail/${record.index}`, {
      record,
      dataTable,
    });
  };

  render() {
    const { dataTable, currentPage, cardsPerPage, total } = this.state;

    const currentPageData = dataTable.slice(
      currentPage * cardsPerPage,
      (currentPage + 1) * cardsPerPage
    );

    return (
      <div className="Hboard_Contaier">
        <FilterBox
          REGION_OPTIONS={REGION_OPTIONS}
          TRADINGFORM_OPTIONS={TRADINGFORM_OPTIONS}
          onRegionSelect={this.handleRegionSelect}
          onTradingFormSelect={this.handleTradingFormSelect}
        />

        <Table
          rowKey={(row) => row.index}
          className="custom-table"
          columns={columns}
          dataSource={currentPageData}
          pagination={false}
          // expandable={{
          //   expandRowByClick: true,
          //   expandedRowRender: (record) => <ModalData item={record} />,
          //   expandIcon: () => <div />,
          // }}
          onRow={(record) => ({
            style: { cursor: "pointer" },
            onClick: () => this.handleRowClick(record, dataTable),
          })}
        />
        {/* <div>
          {this.state.list.map((item, index) => {
            return (
              <NavLink
                className="Link"
                data={item}
                to={`/HanbitBoard/InBoard?name=${item.name}`}
              >
                <BoardText
                  key={index}
                  number={this.state.list.length - index}
                  date={item.simple_resources.createdAt}
                  title={item.resources[0].data[0]}
                  people={item.simple_resources.name}
                />
              </NavLink>
            );
          })}
        </div> */}
        {total > cardsPerPage && (
          <Pagination
            currentPage={currentPage}
            cardsPerPage={cardsPerPage}
            itemCount={total}
            onPageNumberClicked={this.onPageNumberClicked}
            onPreviousPageClicked={this.onPreviousPageClicked}
            onNextPageClicked={this.onNextPageClicked}
          />
        )}
      </div>
    );
  }
}

export default withRouter(Hboard);
