import React, { useRef, useState, useEffect } from "react";

const FilterBox = ({
  REGION_OPTIONS,
  TRADINGFORM_OPTIONS,
  onRegionSelect,
  onTradingFormSelect,
}) => {
  const [dropdownRegionOpen, setDropdownRegionOpen] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState("지역");
  const [dropdownTradingFormOpen, setDropdownTradingFormOpen] = useState(false);
  const [selectedTradingForm, setSelectedTradingForm] = useState("매매 형태");

  const dropdownRegionRef = useRef(null);
  const dropdownTradingFormRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      dropdownRegionRef.current &&
      !dropdownRegionRef.current.contains(event.target)
    ) {
      setDropdownRegionOpen(false);
    }
    if (
      dropdownTradingFormRef.current &&
      !dropdownTradingFormRef.current.contains(event.target)
    ) {
      setDropdownTradingFormOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleDropdownRegion = () => {
    setDropdownRegionOpen((prev) => !prev);
  };

  const handleRegionOptionClick = (region) => {
    setSelectedRegion(region.label);
    setDropdownRegionOpen(false);
    onRegionSelect(region.value);
  };

  const toggleDropdownTradingForm = () => {
    setDropdownTradingFormOpen((prev) => !prev);
  };

  const handleTradingFormOptionClick = (tradingForm) => {
    setSelectedTradingForm(tradingForm.label);
    setDropdownTradingFormOpen(false);
    onTradingFormSelect(tradingForm.value);
  };

  return (
    <div className="Filter_Component">
      <div
        ref={dropdownRegionRef}
        className="custom-select"
        onClick={(e) => e.stopPropagation()}
      >
        <button className="select-button" onClick={toggleDropdownRegion}>
          {selectedRegion} <span className="arrow">&#9662;</span>
        </button>
        {dropdownRegionOpen && (
          <ul className="select-options">
            {REGION_OPTIONS.map((region, index) => (
              <li
                key={index}
                className="option"
                onClick={() => handleRegionOptionClick(region)}
              >
                {region.label}
              </li>
            ))}
          </ul>
        )}
      </div>

      <div
        ref={dropdownTradingFormRef}
        className="custom-select"
        onClick={(e) => e.stopPropagation()}
      >
        <button className="select-button" onClick={toggleDropdownTradingForm}>
          {selectedTradingForm} <span className="arrow">&#9662;</span>
        </button>
        {dropdownTradingFormOpen && (
          <ul className="select-options">
            {TRADINGFORM_OPTIONS.map((trading, index) => (
              <li
                key={index}
                className="option"
                onClick={() => handleTradingFormOptionClick(trading)}
              >
                {trading.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default FilterBox;
