import axios from "axios";

const baseURL = "https://cdn.contentful.com";
const vehicleUrl = "https://cdn.contentful.com";
const hanbitUrl = "https://cdn.contentful.com";
class NoticeService {

  getVehiclesNotices = async (content_type) => {
    const res = await axios.get(
      `${vehicleUrl}/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/${process.env.REACT_APP_CONTENTFUL_ENVIRONMENT_ID}/entries`,
      {
        params: {
          access_token: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
          content_type: content_type,
        },
      }
    );
    return res;
  };
  getHanbitNotices = async (content_type) => {
    const res = await axios.get(
      `${hanbitUrl}/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/${process.env.REACT_APP_CONTENTFUL_ENVIRONMENT_ID}/entries`,
      {
        params: {
          access_token: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
          content_type: content_type,
        },
      }
    );
    return res;
  };
}
export default new NoticeService();
