import React, { Component } from "react";
import Image from "../../_asset/image/main/kakaotalk-banner.png";

import "./style/Recruitment.scss";

class Recruitment extends Component {
  render() {
    return (
      <div className="RecruitContainer">
        <a href="https://open.kakao.com/o/sw49bDzd" target="blank">
          <img src={Image} alt="Join us on KakaoTalk" />
        </a>
      </div>
    );
  }
}

export default Recruitment;
