export const REGION_OPTIONS = [
  { label: "전체", value: "" },
  { label: "서울", value: "서울" },
  { label: "경기도", value: "경기도" },
  { label: "강원도", value: "강원도" },
  { label: "경상남도", value: "경상남도" },
  { label: "경상북도", value: "경상북도" },
  { label: "전라남도", value: "전라남도" },
  { label: "전라북도", value: "전라북도" },
  { label: "충청남도", value: "충청남도" },
  { label: "충청북도", value: "충청북도" },
  { label: "제주도", value: "제주도" },
];

export const TRADINGFORM_OPTIONS = [
  { label: "전체", value: "" },
  { label: "차주 매매", value: "차주 매매" },
  { label: "본사 매매", value: "본사 매매" },
  { label: "매매 완료", value: "매매 완료" },
];
