import React, { Component } from "react";
import "./style/ThreeVehicle.scss";
import { data3, vehicleType } from "./BoardDb";
import noicon from "../../../_asset/image/vehicle/no-writing-icon.png";
import { Pagination } from "../../../Common/Pagination/Pagination";
import axios from "axios";
import { Link } from "react-router-dom";
import { formatDate } from "../../../utils/func";
import { Table } from "antd";
import NoticeService from "../../../Modules/API/Notice.service";
import { withRouter } from "react-router-dom";
import {
  TRADINGFORM_OPTIONS,
  REGION_OPTIONS,
} from "../../../constant/FilterData";
import FilterBox from "../../../Components/Filter/FilterBox";

const columns = [
  {
    title: "번호",
    dataIndex: "index",
    key: 1,
    width: "10%",
  },
  {
    title: "지역",
    dataIndex: "region",
    key: 2,
    width: "12%",
  },
  {
    title: "매매 형태",
    dataIndex: "tradingForm",
    key: 2,
    width: "15%",
  },
  {
    title: "종류",
    dataIndex: "type",
    key: 3,
    width: "15%",
  },
  {
    title: "제목",
    dataIndex: "title",
    key: 4,
    width: "25%",
  },
  {
    title: "작성일",
    key: 5,
    dataIndex: "date",
    width: "10%",
  },
  {
    title: "진행",
    key: 6,
    dataIndex: "progress",
    width: "18%",
  },
];

const VEHICLE_TYPE = "003";
class ThreeVehicle extends Component {
  state = {
    currentPage: 0,
    cardsPerPage: 7,
    total: 0,
    currentPageM: 0,
    cardsPerPageM: 6,
    list: [],
    dataTable: [],
    // cardsperpage -> 보여주고 싶은 박스 개수
    selectedRegion: "",
    selectedTradingForm: "",
  };

  componentDidMount() {
    this.getAllList();
    this.getDataInfo();
  }

  handleRegionSelect = (region) => {
    this.setState({ selectedRegion: region }, this.getDataInfo);
  };

  handleTradingFormSelect = (tradingForm) => {
    this.setState({ selectedTradingForm: tradingForm }, this.getDataInfo);
  };

  onPageNumberClicked = (newPage) => (event) => {
    // console.log(newPage);
    this.handlePageNumberClicked(newPage);
  };
  onPreviousPageClicked = (newPage) => (event) => {
    // console.log(newPage);
    this.handlePreviousPageClicked(newPage);
  };
  onNextPageClicked = (newPage) => (event) => {
    // console.log(newPage);
    this.handleNextPageClicked(newPage);
  };
  handlePageNumberClicked = (newPage) => {
    // console.log("onPageNumberClicked", newPage);
    this.setState({ currentPage: newPage });
  };
  handlePreviousPageClicked = (newPage) => {
    // console.log("onPreviousPageClicked", newPage);
    this.setState({ currentPage: newPage });
  };
  handleNextPageClicked = (newPage) => {
    //console.log("onNextPageClicked", newPage);
    this.setState({ currentPage: newPage });
  };
  filterData = () => {
    let getData = data3;
    let startIndex = this.state.currentPage * this.state.cardsPerPage;
    let endIndex = (this.state.currentPage + 1) * this.state.cardsPerPage;

    return getData.slice(startIndex, endIndex);
  };
  renderList = () => {
    const list = this.state.list;
    let startIndex = this.state.currentPage * this.state.cardsPerPage;
    let endIndex = (this.state.cardsPerPage + 1) * this.state.cardsPerPage;

    return list.slice(startIndex, endIndex);
  };

  getAllList = async () => {
    const rID = "hanbit_vehicle";
    await axios
      .get(`http://61.73.79.136:9229/api/resources?rID=${rID}`)

      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.rows
        ) {
          // console.log(response);

          response.data.data.rows = response.data.data.rows.map((item) => {
            const date = new Date(item.simple_resources.registDt);
            item.simple_resources.registDt = `${date.getFullYear()}-${
              date.getMonth() + 1
            }-${date.getDate()}`;
            return item;
          });

          response.data.data.rows = response.data.data.rows.filter((item) => {
            return item.simple_resources.type === VEHICLE_TYPE;
          });

          this.setState({
            ...this.state,
            list: response.data.data.rows,
          });
        } else {
          console.error("error");
        }
      })
      .catch((e) => console.log(e));
  };
  getDataInfo = async () => {
    const { selectedRegion, selectedTradingForm } = this.state;
    const content_type = "threevehicle";

    try {
      const res = await NoticeService.getVehiclesNotices(content_type);

      if (res.data && res.data.items) {
        const mappedData = res.data.items
          .map((item, index) => ({
            index: index + 1,
            type: item.fields.type,
            title: item.fields.title,
            date: formatDate(item.fields.dateCreated),
            region: item.fields.region || "",
            tradingForm: item.fields.tradingForm || "",
            progress: item.fields.progress || "",
            detail: item.fields?.detail || "",
            key: index,
          }))
          .filter((item) => {
            const matchesRegion =
              !selectedRegion || item.region === selectedRegion;
            const matchesTradingForm =
              !selectedTradingForm || item.tradingForm === selectedTradingForm;
            return matchesRegion && matchesTradingForm;
          });

        this.setState({
          dataTable: mappedData,
          total: mappedData.length,
        });
      }
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  };
  handleRowClick = (record, dataTable) => {
    this.props.history.push(`/vehicle-detail/${record.index}`, {
      record,
      dataTable,
    });
  };

  render() {
    const { list, dataTable, currentPage, cardsPerPage, total } = this.state;

    const currentPageData = dataTable.slice(
      currentPage * cardsPerPage,
      (currentPage + 1) * cardsPerPage
    );

    return (
      <div className="Vehicle_Container">
        <div className="Vehicle_TitleBox">
          <div className="Vehicle_Title">당사차량 기타</div>

          <FilterBox
            REGION_OPTIONS={REGION_OPTIONS}
            TRADINGFORM_OPTIONS={TRADINGFORM_OPTIONS}
            onRegionSelect={this.handleRegionSelect}
            onTradingFormSelect={this.handleTradingFormSelect}
          />

          <Table
            rowKey={(row) => row.index}
            className="custom-table"
            columns={columns}
            dataSource={currentPageData}
            pagination={false}
            // expandable={{
            //   expandRowByClick: true,
            //   expandedRowRender: (record) => <ModalData item={record} />,
            //   expandIcon: () => <div />,

            // }}
            onRow={(record) => ({
              style: { cursor: "pointer" },
              onClick: () => this.handleRowClick(record, dataTable),
            })}
          />
        </div>
        {total > cardsPerPage && (
          <Pagination
            currentPage={currentPage}
            cardsPerPage={cardsPerPage}
            itemCount={total}
            onPageNumberClicked={this.onPageNumberClicked}
            onPreviousPageClicked={this.onPreviousPageClicked}
            onNextPageClicked={this.onNextPageClicked}
          />
        )}
      </div>
    );
  }
}

export default withRouter(ThreeVehicle);
