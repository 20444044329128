import React from "react";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import "./style/ModalData.scss";

function ModalData({ item }) {
  const { detail } = item;

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, next) =>
        `<p>${next(node.content).replace(/\n/g, "<br/>")}</p>`,
    },
    preserveWhitespace: true,
  };

  const htmlString = documentToHtmlString(detail, options);

  return (
    <div style={{ overflow: "auto" }} className="modal-data">
      <div
        dangerouslySetInnerHTML={{ __html: htmlString }}
        style={{ padding: "50px 10px" }}
      ></div>
    </div>
  );
}

export default ModalData;
