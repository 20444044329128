import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ModalData from "./ModalData";
import "./style/HboardDetail.scss";
import BoardBanner from "./function/BoardBanner";

class HboardDetail extends Component {
  handleBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  handleNavigation = (direction) => {
    const { history, location } = this.props;
    const { record, dataTable } = location.state || {};
    let newRecord = null;

    const currentIndex = dataTable.findIndex(
      (item) => item.index === record.index
    );

    if (direction === "prev" && currentIndex > 0) {
      newRecord = dataTable[currentIndex - 1];
    } else if (direction === "next" && currentIndex < dataTable.length - 1) {
      newRecord = dataTable[currentIndex + 1];
    }

    if (newRecord) {
      history.push({
        pathname: `/hanbitboard-detail/${newRecord.index}`,
        state: { record: newRecord, dataTable },
      });
    }
  };

  render() {
    const { location } = this.props;
    const { record, dataTable } = location.state || {};

    const currentIndex = dataTable.findIndex(
      (item) => item.index === record.index
    );
    const prevPost = currentIndex > 0 ? dataTable[currentIndex - 1] : null;
    const nextPost =
      currentIndex < dataTable.length - 1 ? dataTable[currentIndex + 1] : null;

    return (
      <div>
        <div className="TopBanner">
          <BoardBanner {...this.props}></BoardBanner>
        </div>
        <div className="Post_Header">
          <h1 className="Post_Title">{record.title}</h1>
          <div className="Post_Info">
            <span className="author">{record.region}</span>
            <span className="author">{record.tradingForm}</span>
            <span className="author">{record.type}</span>
            <span className="author">작성일 {record.date}</span>
            <span className="date">{record.progress}</span>
          </div>
        </div>
        <ModalData item={record} />
        <div className="Button_Container">
          <div className="Back_Button" onClick={this.handleBack}>
            목록
          </div>
        </div>

        {(prevPost || nextPost) && (
          <div className="Post_Footer">
            {prevPost && (
              <div className="Post_Title">
                <span
                  className="Previous_Post"
                  onClick={() => this.handleNavigation("prev")}
                >
                  ▲ 이전글
                </span>
                <span
                  className="Current_Post"
                  onClick={() => this.handleNavigation("prev")}
                >
                  {prevPost.title}
                </span>
              </div>
            )}

            {nextPost && (
              <div className="Post_Title">
                <span
                  className="Next_Post"
                  onClick={() => this.handleNavigation("next")}
                >
                  ▼ 다음글
                </span>
                <span
                  className="Current_Post"
                  onClick={() => this.handleNavigation("next")}
                >
                  {nextPost.title}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(HboardDetail);
