import React, { Component } from "react";
import "./style/ThreeVehicle.scss";
import { data3, vehicleType } from "./BoardDb";
import noicon from "../../../_asset/image/vehicle/no-writing-icon.png";
import { Pagination } from "../../../Common/Pagination/Pagination";
import axios from "axios";
import { Link } from "react-router-dom";
import { formatDate } from "../../../utils/func";
import { Table } from "antd";
import ModalData from "./ModalData";
import NoticeService from "../../../Modules/API/Notice.service";

const columns = [
  {
    title: "번호",
    dataIndex: "index",
    key: 1,
    width: "15%",
  },
  {
    title: "종류",
    dataIndex: "type",
    key: 2,
    width: "25%",
  },
  {
    title: "제목",
    dataIndex: "title",
    key: 3,
    width: "25%",
  },
  {
    title: "작성일",
    key: 4,
    dataIndex: "date",
    width: "25%",
  },
];

const VEHICLE_TYPE = "004";
class FourVehcle extends Component {
  state = {
    currentPage: 0,
    cardsPerPage: 7,
    total: 0,
    currentPageM: 0,
    cardsPerPageM: 6,
    list: [],
    dataTable: [],

    // cardsperpage -> 보여주고 싶은 박스 개수
  };

  componentDidMount() {
    this.getAllList();
    this.getDataInfo();
  }
  componentDidMount() {
    this.getAllList();
    this.getDataInfo();
  }

  onPageNumberClicked = (newPage) => (event) => {
    // console.log(newPage);
    this.handlePageNumberClicked(newPage);
  };
  onPreviousPageClicked = (newPage) => (event) => {
    // console.log(newPage);
    this.handlePreviousPageClicked(newPage);
  };
  onNextPageClicked = (newPage) => (event) => {
    // console.log(newPage);
    this.handleNextPageClicked(newPage);
  };
  handlePageNumberClicked = (newPage) => {
    // console.log("onPageNumberClicked", newPage);
    this.setState({ currentPage: newPage });
  };
  handlePreviousPageClicked = (newPage) => {
    // console.log("onPreviousPageClicked", newPage);
    this.setState({ currentPage: newPage });
  };
  handleNextPageClicked = (newPage) => {
    //console.log("onNextPageClicked", newPage);
    this.setState({ currentPage: newPage });
  };
  filterData = () => {
    let getData = data3;
    let startIndex = this.state.currentPage * this.state.cardsPerPage;
    let endIndex = (this.state.currentPage + 1) * this.state.cardsPerPage;

    return getData.slice(startIndex, endIndex);
  };
  renderList = () => {
    const list = this.state.list;
    let startIndex = this.state.currentPage * this.state.cardsPerPage;
    let endIndex = (this.state.cardsPerPage + 1) * this.state.cardsPerPage;

    return list.slice(startIndex, endIndex);
  };

  getAllList = async () => {
    const rID = "hanbit_vehicle";
    await axios
      .get(`http://61.73.79.136:9229/api/resources?rID=${rID}`)

      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.rows
        ) {
          // console.log(response);

          response.data.data.rows = response.data.data.rows.map((item) => {
            const date = new Date(item.simple_resources.registDt);
            item.simple_resources.registDt = `${date.getFullYear()}-${
              date.getMonth() + 1
            }-${date.getDate()}`;
            return item;
          });

          response.data.data.rows = response.data.data.rows.filter((item) => {
            return item.simple_resources.type === VEHICLE_TYPE;
          });

          this.setState({
            ...this.state,
            list: response.data.data.rows,
          });
        } else {
          console.error("error");
        }
      })
      .catch((e) => console.log(e));
  };
  getDataInfo = async () => {
    const content_type = "fourvehicle";
    await NoticeService.getVehiclesNotices(content_type).then((res) => {
      const mappedData = res.data.items.map((item, index) => {
        let newRow = {};
        newRow.index = index + 1;
        newRow.type = item.fields.type;
        newRow.title = item.fields.title;
        newRow.date = formatDate(item.fields.dateCreated);
        newRow.detail = item.fields?.detail || "";
        newRow.key = index;
        return newRow;
      });
      this.setState({
        ...this.state,
        dataTable: mappedData,
        cardsPerPage: res.data.limit,
      });
    });
  };
  render() {
    const { list, dataTable, currentPage, cardsPerPage, total } = this.state;
    return (
      <div className="Vehicle_Container">
        <div className="Vehicle_TitleBox">
          <div className="Vehicle_Title">당사차량 [5톤]</div>
          <Table
            rowKey={(row) => row.index}
            className="custom-table"
            columns={columns}
            dataSource={dataTable}
            pagination={false}
            expandable={{
              expandRowByClick: true,
              expandedRowRender: (record) => <ModalData item={record} />,
              expandIcon: () => <div />,
            }}
          />
        </div>
        {total > cardsPerPage && (
          <Pagination
            currentPage={currentPage}
            cardsPerPage={cardsPerPage}
            itemCount={total}
            onPageNumberClicked={this.onPageNumberClicked}
            onPreviousPageClicked={this.onPreviousPageClicked}
            onNextPageClicked={this.onNextPageClicked}
          />
        )}
      </div>
    );
  }
}

export default FourVehcle;
