import React, { Component } from "react";
import "./style/IntroText.scss";

class IntroText extends Component {
  render() {
    return (
      <div className="TextContainer">
        <div className="Wrap_Line">
          <div className="Title">
            <span>CEO</span> 인사말
          </div>
          <div className="VisitText">
            한빛로지스(주) 홈페이지 방문을
            <br />
            진심으로 환영합니다.
          </div>
          <div className="Text">
            <p>
              안녕하십니까?
              <br />
            </p>
            <p>
              당사는 화물 운송 서비스를 제공하고 있는 국내 최고의 물류 전문
              회사로서 신뢰와 성실을 바탕으로 회사의 안정적인&nbsp;
              <br />
              발전을 도모하고, 나아가 지역 사회 발전과 국가 경제발전에
              기여하고자 노력하여 왔습니다. 현재 편의점 업계의&nbsp;
              <br />
              BGF리테일(CV), 이마트24와 기업물류 계약을 맺어, 운송하고
              있습니다.&nbsp;
              <br />
            </p>
            <p>
              최상의 운송 여건을 갖추기 위한 특장차에 대한 지속적인 연구와
              신속한 Delivery를 위한 각종 통신장비 휴대,&nbsp;
              <br />
              각종 사고에 신속하게 대처하기 위한 안전교육 실시와 특약보험 가입
              등 첨단 장비와 우수한 인력을 바탕으로 고객&nbsp;
              <br />
              서비스에 만전을 기하고 있습니다.&nbsp;
              <br />
            </p>
            <p>
              또한, 21세기를 맞이하여 물류 산업의 국제화와 세계화에 부응하고
              경쟁력을 제공하기 위하여 운송 시스템의 영역을&nbsp;
              <br />
              확충함은 물론 그동안 부분별로 운영해오던 개별 법인들에 대한 통합
              운영 관리 체제를 구축하였습니다.&nbsp;
              <br />
              저희 임직원은 지속적인 창의성 개발과 끊임없는 변화 추구로 화물
              운송 서비스를 신지식 산업으로 발전 시켜&nbsp;
              <br />
              고객 여러분이 만족하실 때까지 최선의 노력을 다할 것입니다.&nbsp;
              <br />
            </p>
            <p>
              당사는 지난 수년간 운송 용역을 담당하였던 고객회사에 무결점 우송
              서비스를 제공하여 그 위상을 드높여 왔습니다.&nbsp;
              <br />
              저희 한빛로지스(주) 는 지금까지의 실적에 만족하지 않고 그동안
              당사를 애용해 주신 고객 여러분에게 감사드리면서&nbsp;
              <br />
              더욱 향상된 서비스를 제공할 것을 약속드립니다.&nbsp;
              <br />
            </p>
            끝으로 기본에 충실하여 최고보다 최선을 다한다는 정신으로 사랑받고
            발전과 성장하는 한빛로지스(주) 가 되도록&nbsp;
            <br />
            최선을 다하겠습니다.
          </div>
        </div>
      </div>
    );
  }
}

export default IntroText;
