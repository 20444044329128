import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ModalData from "./ModalData";
import "./style/VehicleDetail.scss";
import OneCarBanner from "./OneCarBanner";
import CarMenuBox from "../../../Components/MenuBox/CarMenu";

class VehicleDetail extends Component {
  isMobile = window.innerWidth <= 768;
  handleBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  handleNavigation = (direction) => {
    const { history, location } = this.props;
    const { record, dataTable } = location.state || {};
    let newRecord = null;

    const currentIndex = dataTable.findIndex(
      (item) => item.index === record.index
    );

    if (direction === "prev" && currentIndex > 0) {
      newRecord = dataTable[currentIndex - 1];
    } else if (direction === "next" && currentIndex < dataTable.length - 1) {
      newRecord = dataTable[currentIndex + 1];
    }

    if (newRecord) {
      history.push({
        pathname: `/vehicle-detail/${newRecord.index}`,
        state: { record: newRecord, dataTable },
      });
    }
  };

  render() {
    const { location } = this.props;
    const { record, dataTable } = location.state || {};

    const currentIndex = dataTable.findIndex(
      (item) => item.index === record.index
    );
    const prevPost = currentIndex > 0 ? dataTable[currentIndex - 1] : null;
    const nextPost =
      currentIndex < dataTable.length - 1 ? dataTable[currentIndex + 1] : null;

    return (
      <div className="Vehicle_Contatiner">
        <div className="TopBanner">
          <OneCarBanner></OneCarBanner>
        </div>
        <div className="Vehicle_Contents">
          <CarMenuBox isMobile={this.isMobile} {...this.props}></CarMenuBox>
          <div>
            <div className="Post_Header">
              <h1 className="Post_Title">{record.title}</h1>
              <div className="Post_Info">
                <p className="content">
                  <span className="category">지역 </span>
                  {record.region}
                </p>
                <p className="content">
                  <span className="category">매매 형태 </span>
                  {record.tradingForm}
                </p>
                <p className="content">
                  <span className="category">종류 </span>
                  {record.type}
                </p>
                <p className="content">
                  <span className="category">작성일 </span> {record.date}
                </p>
                <p className="content">
                  <span className="category">진행 </span>
                  {record.progress}
                </p>
              </div>
            </div>
            <ModalData item={record} />
            <div className="Button_Container">
              <div className="Back_Button" onClick={this.handleBack}>
                목록
              </div>
            </div>

            {(prevPost || nextPost) && (
              <div className="Post_Footer">
                {prevPost && (
                  <div className="Post_Title">
                    <span
                      className="Previous_Post"
                      onClick={() => this.handleNavigation("prev")}
                    >
                      ▲ 이전글
                    </span>
                    <span
                      className="Current_Post"
                      onClick={() => this.handleNavigation("prev")}
                    >
                      {prevPost.title}
                    </span>
                  </div>
                )}

                {nextPost && (
                  <div className="Post_Title">
                    <span
                      className="Next_Post"
                      onClick={() => this.handleNavigation("next")}
                    >
                      ▼ 다음글
                    </span>
                    <span
                      className="Current_Post"
                      onClick={() => this.handleNavigation("next")}
                    >
                      {nextPost.title}
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(VehicleDetail);
