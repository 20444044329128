import React from "react";
import "./style/Guide.scss";

const Guide = ({ handleClickMenu }) => {
  return (
    <div className="GuideContainer">
      <div className="GuideTop">
        <div
          className="GuideAbout"
          onMouseEnter={() => handleClickMenu("Intro")}
          onClick={() => handleClickMenu("Intro")}
        >
          <a href="/Intro/ceo" style={{ color: "black" }}>
            <div to="/Intro">회사소개</div>
          </a>
        </div>
        <div
          className="GuideInfo"
          onMouseEnter={() => handleClickMenu("Info")}
          onClick={() => handleClickMenu("Info")}
        >
          <a href="/Info/analysis">
            <div to="/Info">지입정보</div>
          </a>
        </div>
        <div
          className="GuideCar"
          onMouseEnter={() => handleClickMenu("Vehicle")}
          onClick={() => handleClickMenu("Vehicle")}
        >
          <a href="/Vehicle/onevehicle">
            <div to="/Vehicle">당사차량</div>
          </a>
        </div>
        <div
          className="GuideBoard"
          onMouseEnter={() => handleClickMenu("HanbitBoard")}
          onClick={() => handleClickMenu("HanbitBoard")}
        >
          <a href="/HanbitBoard">
            <div to="/HanbitBoard">물량 정보</div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Guide;
